<template>
  <div id="app">
    <div class="container">
      <div class="row header-row">
        <div class="full column" style="margin-top: 3%">
          <h1>Lake Decatur Real-Time Water Monitoring</h1>
        </div>
      </div>

      <div class="row content-row" v-if="sites.length > 0">
        <div class="full-column">
          <h2 class="section-header">Monitoring Locations</h2>
          <table class="sites-table u-full-width">
            <thead>
              <tr class="table-header">
                <th>Location</th>
                <th>Available Data</th>
                <th class="drainage-col optional-col">Drainage Area</th>
                <th class="isws-col optional-col">ISWS ID</th>
                <th>Telemetry</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="site in sites" :key="site.code">
                <td class="location-col">
                  <a :href="`/dashboard/${site.id}/`">
                    {{site.description}}
                  </a>
                </td>
                <td class="parameter-list">{{site.parameter_list}}</td>
                <td class="drainage-col optional-col">{{site.drainage_area}} mi<sup>2</sup></td>
                <td class="isws-col optional-col">{{site.isws_id}}</td>
                <td class="telemetry-status-col" v-if="site.telemetry_age_seconds < 7200">
                  <strong class="telemetry-status success">Online</strong>
                </td>
                <td class="telemetry-status-col" v-else>
                  <strong class="telemetry-status failure">Out of Date</strong>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="row content-row" v-if="sites.length > 0">
        <div class="full-column">
          <h2 class="section-header">External Resources</h2>
          <div class="resources-section">
            <ul>
              <li>Sangamon River
                <ul>
                  <li>
                    <a href="https://waterdata.usgs.gov/monitoring-location/05572000/#parameterCode=00065&period=P7D"> USGS Monitoring Page for Sangamon River at Montecello </a>
                  </li>
                  <li>
                    <a href="https://water.weather.gov/ahps2/hydrograph.php?gage=mnti2&wfo=ilx"> NWS Forecast Page for Sangamon River at Monticello</a>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="row content-row" v-if="sites.length > 0">
        <div class="full column">
          <h2 class="section-header">Questions and Support</h2>
          <p>For help with this site or to report any issues, email Water Technologies at
             <a href="info@watertechnologies.us">info@watertechnologies.us</a>.
          </p>
        </div>
      </div>

      <div class="row logo-row">
        <div class="full column logo-column">
          <div class="logo-container">
            <div class="logo">
              <img
                alt="City of Decatur Logo"
                src="/img/cod_logo.png"
              />
            </div>
            <div class="logo">
              <img
                alt="National Great Rivers Research and Education Center Logo"
                src="/img/ngrrec_logo.png"
              />
            </div>
            <div class="logo">
              <img
                alt="Illinois State Water Survey Logo"
                src="/img/isws_logo.png"
              />
            </div>
            <div class="logo">
              <img
                alt="Northwater Logo"
                src="/img/nw_logo.png"
              />
            </div>
            <div class="logo">
              <img
                alt="Water Technologies International Logo"
                src="/img/wti_logo.png"
              />
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'App',
  components: {
  },
  data() {
    return {
      sites: [],
    }
  },
  computed: {
  },
  mounted() {
    axios('/api/sites').then((resp) => {
      this.sites = resp.data.map((site) => {
        let paramList = ['Stage']

        if (site.has_curve) {
          paramList.push('Discharge')
        }

        if (site.displayed_parameters) {
          paramList = paramList.concat(site.displayed_parameters)
        }

        return { ...site, parameter_list: paramList.join(', ') }
      })
    })
  },
}
</script>

<style scoped>
@import '/public/css/styles.css';

.parameter-header {
  text-transform: uppercase;
  font-size: 1.4rem;
  letter-spacing: .2rem;
  font-weight: 600;
}
.parameter-list {
  font-size: 1.4rem;
}
th{
  white-space:nowrap;
}
.telemetry-status-col {
  text-align: left;
}
.location-col {
  min-width: 9rem;
}
.isws-col {
  text-align: center;
}
.drainage-col {
  text-align: right;
}
.header-row {
  margin-bottom: 0.5rem;
}
.content-row {
  margin-bottom: 1rem;
  padding-top: 1rem;
}
.section-header {
  display: inline;
}
.sites-table {
  margin-top: 0.5rem;
  margin-bottom: 1.5rem;
}
.resources-section {
  margin-top: 1rem;
}
.telemetry-status.success {
  color: #3B813B;
}
.telemetry-status.failure {
  color: #666;
}
.link-cell {
  font-style: bold;
  text-align: left;
}
.logo-container {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  margin-top: 2rem;
  margin-bottom: 4rem;
  flex-wrap: wrap;
}
.logo {
  margin-bottom: 1rem;
}
.logo img {
  width: 180px;
  max-width: 100%;
  height: 90px;
  object-fit: contain;
}
@media (max-device-width: 1024px)  {
  .optional-col {
    display: none;
  }
  .location-col {
    min-width: 0rem;
  }
}
</style>
